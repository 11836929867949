//Color shades
$primary-color-dark:      darken($primary-color, 10%);
$primary-color-darkest:   darken($primary-color, 20%);
$primary-color-light:     lighten($primary-color, 10%);
$primary-color-lightest:  lighten($primary-color, 20%);

$danger-color-dark:       darken($danger-color, 10%);
$danger-color-darkest:    darken($danger-color, 20%);
$danger-color-light:      lighten($danger-color, 10%);
$danger-color-lightest:   lighten($danger-color, 20%);

$warning-color-dark:      darken($warning-color, 10%);
$warning-color-darkest:   darken($warning-color, 20%);
$warning-color-light:     lighten($warning-color, 10%);
$warning-color-lightest:  lighten($warning-color, 20%);

$success-color-dark:      darken($success-color, 10%);
$success-color-darkest:   darken($success-color, 20%);
$success-color-light:     lighten($success-color, 10%);
$success-color-lightest:  lighten($success-color, 20%);

$grey-color-dark:         darken($grey-color, 10%);
$grey-color-darkest:      darken($grey-color, 20%);
$grey-color-light:        lighten($grey-color, 10%);
$grey-color-lightest:     lighten($grey-color, 20%);

//Text contrast
$primary-color-text:      #5f99f5;
$success-color-text:      #37b358;
$info-color-text:         $info-color;
$warning-color-text:      #f6c163;
$danger-color-text:       #eb6357;
$grey-color-text:         darken($grey-color, 6%);
$dark-color-text:         #333333;

$color-red         : #f44336;
$color-pink        : #e91e63;
$color-purple      : #9c27b0;
$color-deep-purple : #673ab7;
$color-indigo      : #3f51b5;
$color-blue        : #2196f3;
$color-light-blue  : #03a9f4;
$color-cyan        : #00bcd4;
$color-teal        : #009688;
$color-green       : #4caf50;
$color-light-green : #8bc34a;
$color-lime        : #cddc39;
$color-yellow      : #ffeb3b;
$color-amber       : #ffc107;
$color-orange      : #ff9800;
$color-deep-orange : #ff5722;
$color-brown       : #795548;
$color-grey        : #9e9e9e;
$color-blue-grey   : #607d8b;
$color-black       : #000000;
$color-white       : #ffffff;


@mixin text-color($color) {
	color:$color;
}

@mixin bg-color($color)
{
	background-color: $color;
}

.text-red{
	@include text-color($color-red);
}

.text-pink{
	@include text-color($color-pink);
}

.text-purple{
	@include text-color($color-purple);
}

.text-deep-purple{
	@include text-color($color-deep-purple);
}

.text-indigo{
	@include text-color($color-indigo);
}

.text-blue{
	@include text-color($color-blue);
}

.text-light-blue{
	@include text-color($color-light-blue);
}

.text-cyan{
	@include text-color($color-cyan);
}

.text-teal{
	@include text-color($color-teal);
}

.text-green{
	@include text-color($color-green);
}

.text-light-green{
	@include text-color($color-light-green);
}

.text-lime{
	@include text-color($color-lime);
}

.text-yellow{
	@include text-color($color-yellow);
}

.text-amber{
	@include text-color($color-amber);
}

.text-orange{
	@include text-color($color-orange);
}

.text-deep-orange{
	@include text-color($color-deep-orange);
}

.text-brown{
	@include text-color($color-brown);
}

.text-grey{
	@include text-color($color-grey);
}

.text-blue-grey{
	@include text-color($color-blue-grey);
}

.text-black{
	@include text-color($color-black);
}

.text-white{
	@include text-color($color-white);
}

.bg-red{
	@include bg-color($color-red);
}

.bg-pink{
	@include bg-color($color-pink);
}

.bg-purple{
	@include bg-color($color-purple);
}

.bg-deep-purple{
	@include bg-color($color-deep-purple);
}

.bg-indigo{
	@include bg-color($color-indigo);
}

.bg-blue{
	@include bg-color($color-blue);
}

.bg-light-blue{
	@include bg-color($color-light-blue);
}

.bg-cyan{
	@include bg-color($color-cyan);
}

.bg-teal{
	@include bg-color($color-teal);
}

.bg-green{
	@include bg-color($color-green);
}

.bg-light-green{
	@include bg-color($color-light-green);
}

.bg-lime{
	@include bg-color($color-lime);
}

.bg-yellow{
	@include bg-color($color-yellow);
}

.bg-amber{
	@include bg-color($color-amber);
}

.bg-orange{
	@include bg-color($color-orange);
}

.bg-deep-orange{
	@include bg-color($color-deep-orange);
}

.bg-brown{
	@include bg-color($color-brown);
}

.bg-grey{
	@include bg-color($color-grey);
}

.bg-blue-grey{
	@include bg-color($color-blue-grey);
}

.bg-black{
	@include bg-color($color-black);
}

.bg-white{
	@include bg-color($color-white);
}