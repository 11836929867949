//Config variables
@import "includes/variables.scss";

// NPM BOOTSTRAP
@import "~bootstrap/scss/bootstrap.scss";

// NPM FONT AWESOME V5
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";

// Color variations
@import "includes/colors.scss";

.bg-code{
	background-image: url('../img/code.jpeg');

	h1, h2, p{
		color:#ffffff;
	}
}

.footer-icon{
	font-size:1.5rem;
	color:#222222;
}

.contact-icon{
	font-size:2.5rem;
	color:#222222;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.bank-operation-checked-bg{
  background-color: lighten($color-light-blue, 40);
}